import { getAuth, onAuthStateChanged } from "firebase/auth";

export default defineNuxtPlugin(() => {
	addRouteMiddleware("auth", () => {
		const auth = getAuth();

		return new Promise((resolve) => {
			onAuthStateChanged(auth, (user) => {
				if (!user) {
					resolve(navigateTo("/login"));
				} else {
					resolve();
				}
			});
		});
	});
});
