<script setup lang="ts">
import { getSecondsSinceEpoch } from '~/utils/helpers/dates'

useHead({
  titleTemplate: (title) => {
    const websiteTitle = "EmployWell";
    return title ? `${title} | ${websiteTitle}` : websiteTitle;
  },
});
</script>

<template>
  <div>
    <Head> </Head>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
