import { default as indexforEcGrWllMeta } from "/Users/blake/Repos/Github/EmployWell.S6.Web/pages/index.vue?macro=true";
import { default as login1GWVRIlCfZMeta } from "/Users/blake/Repos/Github/EmployWell.S6.Web/pages/login.vue?macro=true";
import { default as _91id_932UcaZGubzSMeta } from "/Users/blake/Repos/Github/EmployWell.S6.Web/pages/patient-details/[id].vue?macro=true";
import { default as payer_45insightspOXRXnO5MEMeta } from "/Users/blake/Repos/Github/EmployWell.S6.Web/pages/payer-insights.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexforEcGrWllMeta || {},
    component: () => import("/Users/blake/Repos/Github/EmployWell.S6.Web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/Users/blake/Repos/Github/EmployWell.S6.Web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "patient-details-id",
    path: "/patient-details/:id()",
    meta: _91id_932UcaZGubzSMeta || {},
    component: () => import("/Users/blake/Repos/Github/EmployWell.S6.Web/pages/patient-details/[id].vue").then(m => m.default || m)
  },
  {
    name: "payer-insights",
    path: "/payer-insights",
    meta: payer_45insightspOXRXnO5MEMeta || {},
    component: () => import("/Users/blake/Repos/Github/EmployWell.S6.Web/pages/payer-insights.vue").then(m => m.default || m)
  }
]