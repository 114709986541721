import {
	getAuth,
	setPersistence,
	browserLocalPersistence,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	User,
} from "firebase/auth";
import { useRouter } from "vue-router";

export const signInUser = async (email: string, password: string) => {
	const auth = getAuth();
	try {
		await setPersistence(auth, browserLocalPersistence);
		const credentials = await signInWithEmailAndPassword(auth, email, password);
		return credentials;
	} catch (error: any) {
		console.error(error.code, error.message);
		return { error: error.message };
	}
};

export const initUser = async () => {
	const auth = getAuth();
	const firebaseUser = useFirebaseUser();
	const userCookie = useCookie("userCookie", {
		expires: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000),
	}); // Set expires to a Date object
	const router = useRouter();

	try {
		// Set persistence to local for 72-hour duration
		await setPersistence(auth, browserLocalPersistence);
	} catch (error) {
		console.error("Failed to set persistence:", error);
	}

	onAuthStateChanged(auth, (user: User | null) => {
		if (user) {
			firebaseUser.value = user;
			// Ensure userCookie stores a JSON string
			userCookie.value = JSON.stringify({
				uid: user.uid,
				email: user.email,
				displayName: user.displayName,
			});

			if (router.currentRoute.value.path === "/login") {
				router.push("/");
			}
		} else {
			firebaseUser.value = null;
			userCookie.value = null;

			if (router.currentRoute.value.path !== "/login") {
				router.push("/login");
			}
		}
	});

	if (typeof userCookie.value === "string") {
		try {
			const parsedUser = JSON.parse(userCookie.value);
			if (parsedUser) {
				firebaseUser.value = parsedUser;
			}
		} catch (error) {
			console.error("Failed to parse userCookie:", error);
			userCookie.value = null;
		}
	}
};

export const signOutUser = async () => {
	const auth = getAuth();
	const userCookie = useCookie("userCookie");
	try {
		await auth.signOut();
		userCookie.value = null;
		return { success: true };
	} catch (error) {
		console.error("Sign out error:", error);
		return { error: "Failed to sign out" };
	}
};
