import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initUser } from "~/composables/useFirebase";

export default defineNuxtPlugin(() => {
	const firebaseConfig = {
		apiKey: "AIzaSyA0d6e57CLQZ-5RY55XWjPEaWA9NuskmfI",
		authDomain: "employwell-web-app.firebaseapp.com",
		projectId: "employwell-web-app",
		storageBucket: "employwell-web-app.appspot.com",
		messagingSenderId: "288239708297",
		appId: "1:288239708297:web:3991154a1e41255e0d3e58",
		measurementId: "G-T09S3EG62T",
	};

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const auth = getAuth(app);

	initUser();

	return {
		provide: {
			firebaseApp: app,
			db,
			auth,
		},
	};
});
